<template>
  <div class="account_settings">
    <!-- 居中 -->
    <div class="content">
      <!-- 面包屑导航 start -->
      <bread-crumb :nav_title="nav_title" />
      <!-- 面包屑导航 end -->

      <div class="content_box">
        <!-- 侧边导航 start -->
        <nav-left :xuanzhong="1" />
        <!-- 侧边导航 end -->

        <!-- 右边内容 -->
        <div class="content_total">
          <div class="nav">
            <div class="information">
              <div class="information_person">
                <!-- 头像 -->
                <div class="demo_type">
                  <el-avatar :size="100">
                    <img :src="userdata.head_link" />
                  </el-avatar>
                </div>
                <!-- 用户信息 start -->
                <div class="users">
                  用户名
                  <span>{{ userdata.name }}</span>
                </div>
                <!-- 昵称 -->
                <div class="user_names names">
                  <el-form :label-position="labelPosition" label-width="44px">
                    <el-form-item label="昵称">
                      <el-input
                        v-model="userdata.nickname"
                        placeholder="请输入昵称"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <!-- 性别 -->
                <div class="gender names">
                  <span class="">性别</span>
                  <el-radio-group v-model="sex">
                    <el-radio :label="0">保密</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </div>
                <!-- 留学意向 -->
                <div class="liuxue_intention">
                  <el-form :label-position="labelPosition" label-width="80px">
                    <el-form-item label="留学意向">
                      <el-input
                        v-model="userdata.countries"
                        placeholder="请输入意向国家"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                      <el-input
                        v-model="userdata.university"
                        placeholder="请输入意向大学"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                      <el-input
                        v-model="userdata.specialty"
                        placeholder="请输入意向专业"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
                <!-- 提交 -->
                <button class="btn" @click="updateInfo" v-if="is_sub">
                  <span>提交</span>
                </button>
                <!-- 用户信息 end -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import breadCrumb from "./components/breadcrumb";
import NavLeft from "./components/NavLeft";
import { getInfo, updateInfo } from "@/api/info.js";
export default {
  data() {
    return {
      sex: 0,
      nav_title: [{ name: "账户设置" }, { name: "个人信息" }],
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        school: "",
        type: "",
        nickname: "",
      },
      userdata: "",
      is_sub: true,
    };
  },
  methods: {
    getInfo() {
      // 获取用户类型是顾问还是学生
      let user_types = this.$cookie.get("user_types");
      console.log(111,user_types);
      if(user_types === 'ccuser'){
        this.is_sub = false;
      }

      let token = this.$cookie.get("token");
      getInfo({ token }).then((res) => {
        console.log(111, res);
        this.userdata = res.data;
        this.sex = this.userdata.sex;
      });
    },
    updateInfo() {
      let user_types = this.$cookie.get("user_types");
      // 获取要修改的数据
      let infodatas = {
        'nickname':this.userdata.nickname,
        'sex':this.sex,
        'group_user':user_types,
        'countries':this.userdata.countries,
        'university':this.userdata.university,
        'specialty':this.userdata.specialt,
        'head_link':this.userdata.head_link,
      };
      updateInfo(infodatas).then((res) => {
         this.$message({
          message: '成功',
          type: 'success'
        });
      });
    },
  },
  components: {
    breadCrumb: breadCrumb,
    NavLeft,
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.account_settings {
  background: #f5f5f5;
  padding-bottom: 75px;
  .content {
    width: 1200px;
    margin: 0 auto;
    // 左边侧边栏
    .content_box {
      display: flex;
    }
    .content_total {
      display: flex;
      .nav {
        width: 990px;
        height: 735px;
        background: #ffffff;
        border-radius: 8px;
        margin-left: 10px;
        .information {
          width: 990px;
          height: 325px;
          background: url(./images/message.png) no-repeat center;
          .information_person {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }
        }
        /deep/.el-avatar {
          margin: 50px 450px 0 440px;
        }
        // 用户名
        .users {
          margin-left: 249px;
          margin-top: 79px;
          span {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
          }
        }
        // 昵称
        .user_names {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          /deep/ .el-input__inner {
            background: #fafafa;
            border: 1px solid #ededed;
            width: 51%;
            height: 44px;
            line-height: 44px;
            color: #333333;
            font-size: 16px;
          }
          /deep/ .el-form-item__label {
            color: #999999;
            font-size: 16px;
          }
        }
        // 公用间距
        .names {
          margin-left: 266px;
          margin-top: 34px;
        }
        // 性别 start
        .gender > span {
          margin-right: 20px;
        }
        /deep/.el-radio__input.is-checked .el-radio__inner {
          background: #ffffff;
        }
        /deep/.el-radio__inner {
          width: 20px;
          height: 20px;
        }
        /deep/.el-radio__inner::after {
          width: 10px;
          height: 10px;
          background: #1890ff;
        }
        /deep/.el-radio__label {
          font-size: 16px;
        }
        // 性别 end
        .liuxue_intention {
          margin-left: 233px;
          margin-top: 34px;
          /deep/ .el-input__inner {
            background: #fafafa;
            border: 1px solid #ededed;
            width: 51%;
            height: 44px;
            line-height: 44px;
            color: #333333;
            font-size: 16px;
          }
          /deep/ .el-form-item__label {
            color: #999999;
            font-size: 16px;
          }
        }
        // 提交
        .btn {
          width: 350px;
          height: 50px;
          background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
          border-radius: 4px;
          border: none;
          margin: 20px 0 0 310px;
          outline: none;
          span {
            font-size: 16px;
            font-weight: 500;
            color: #935d14;
          }
        }
      }
    }
  }
}
</style>
